import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide55/image/imgPortadaGuia.png"
import img1 from "@components/pageGuide/guides/guide55/image/img1.png"
import slider1 from "@components/pageGuide/guides/guide55/image/silder1.png"
import slider2 from "@components/pageGuide/guides/guide55/image/silder2.png"
import slider3 from "@components/pageGuide/guides/guide55/image/silder3.png"

const title = (
  <>
    <h1 className="titles-underlined-ebook ebook-ventas">
      Chatbot educativo: Los mejores usos de los bots para este sector
    </h1>
  </>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook guide__description">
      Descubre los casos de uso de los chatbots en el sector educativo, transformando la interacción de las instituciones con estudiantes potenciales. Estos bots gestionan eficientemente procesos como la matrícula y la atención de preguntas frecuentes, mejorando así la experiencia del usuario en las instituciones educativas.
    </p>
  </div>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
  </span>
)

const textOne = (
  <p>
    Explora cómo esta tecnología optimiza la comunicación y facilita el acceso a información clave, promoviendo una interacción más ágil y personalizada.
    <br /><br />
    Aprende sobre los beneficios de implementar chatbots para modernizar las estrategias de servicio al cliente y optimizar recursos en el ámbito educativo, adaptándose a las necesidades cambiantes de estudiantes y administradores educativos.
  </p>
)

const data = {
  start: {
    support: "Guía | Educación",
    title: title,
    description: textDescription,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-8.pdf",
    brochure: "Guía",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Autoservicio en FAQs sobre cursos.",
      },
      {
        key: 2,
        text: "Asistencia en registros.",
      },
      {
        key: 3,
        text: "Ayuda en transacciones.",
      },
      {
        key: 4,
        text: "Feedback para estudiantes.",
      },
      {
        key: 5,
        text: "Asistencia para docentes.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },

  description: {
    cards: [
      {
        text: textOne,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
